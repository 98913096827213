/* Themed globals */
html, html.style-dark {
	--c-back: #0a1a20;
	--c-fore: #c7c7c7;
	--c-pri: #a0e1ff;
	--c-sec: #74d6ff;
	--c-pad: #253c47;
	--c-last: #122b35;
	--f-back: brightness(0%) invert(16%);
	--f-fore: brightness(0%) invert(78%);
}
html.style-light {
}

/* The real work */
a {
	text-decoration: none;
	color: var(--c-sec);
	transition: color 0.2s linear;
}
a:hover {
	color: var(--c-pri);
}
body {
	font-family: "Noto Sans", "Segoe UI", sans-serif;
	background: var(--c-back);
	color: var(--c-fore);
}
th {
	vertical-align: baseline;
}

/* Specifics */
.is-hidden {
	display: none;
}
.is-bold {
	font-weight: bold;
}
.is-button {
	background: var(--c-pri);
	color: var(--c-back);
	padding: 8px !important;
	border: 0 solid;
	border-radius: 8px;
	cursor: pointer;
	text-align: center;
	transition: transform 0.2s;
	transform: scale(100%);
}
.is-button:hover {
	transform: scale(102%);
}
a.is-active {
	color: var(--c-fore);
}
.container {
	width: 80vw;
	min-width: 270px;
	max-width: 1280px;
	margin: 16px auto;
	background: var(--c-last);
	padding: 16px calc(16px + 0.83em);
	border: 0 solid;
	border-radius: 16px;
}
.footer {
	width: 80vw;
	min-width: 270px;
	max-width: 1280px;
	margin: 4px auto;
	padding: 4px calc(4px + 0.83em);
	text-align: center;
}
.imgbox {
	text-align: center;
}
.imgbox > img {
	max-width: 100%;
	max-height: 192px;
	border: 0 solid;
	border-radius: 4px;
}
.imgbox.imgbox-small > img {
	max-height: 96px;
}
img.icon {
	height: 1.2em;
	filter: var(--f-fore);
	vertical-align: sub;
}
/* Nav bar */
.nav {
	margin: 4px 0;
	padding: 0;
	display: flex;
}
.nav > li {
	flex: 1;
}
.nav li {
	display: inline-block;
}
.nav .nav-left {
}
.nav .nav-right {
	text-align: right;
}
html[dir="rtl"] .nav .nav-right {
	text-align: left;
}
.nav-lang {
	position: absolute;
	top: 20px;
	right: calc(10% + max(0px, 40% - 640px) - 24px);
	background: var(--c-pad);
	cursor: default;
	padding: 12px 16px;
	border: 0 solid;
	border-radius: 8px;
	z-index: 114514;
}
html[dir="rtl"] .nav-lang {
	left: calc(10% + max(0px, 40% - 640px) - 16px);
	/* width: max-content; */
}
.nav-lang li {
	display: block;
	margin: 0.5em;
}
.nav-right:hover .nav-lang, .nav-lang:hover {
	display: block;
}
.nav-btn {
	background: var(--c-pri);
	color: var(--c-back);
	padding: 12px;
	margin: -12px;
	border: 0 solid;
	border-radius: 8px;
}
.nav-btn > img {
	filter: var(--f-back);
}
/* Cards and collections */
.cards {
	padding: 0;
	display: grid;
	gap: 8px;
	grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
	justify-content: center;
	align-items: top;
}
@media (min-width: 1000px) {
	.cards {
		grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
	}
}
.cards.cards-small {
	grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
}
.cards > li {
	display: flex;
	flex-direction: column;
	background: var(--c-pad);
	padding: 8px;
	/* min-height: 144px; */
	border: 0 solid;
	border-radius: 16px;
	cursor: default;
}
.card-head {
	font-weight: bold;
	text-align: center;
	font-size: 1.25em;
	padding: 4px;
	padding-bottom: 0;
}
.card-body {
	flex: 1;
	padding: 4px;
}
.card-body > div {
	margin: 4px;
	padding: 4px;
}
.card-body > div > table th {
	text-align: right;
}
html[dir="rtl"] .card-body > div > table th {
	text-align: left;
}
.card-tail {
	padding: 4px;
}
